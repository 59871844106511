<template>
  <dashboard>
    <el-row :gutter="15">
      <el-col :xs="24" :md="24">
        <el-card class="notifications">
          <div class="header">
            <h5>Notifications</h5>
          </div>
          <div class="body">
            
          </div>
        </el-card>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
  import Dashboard from '~/components/Dashboard/Dashboard'

  export default {
    components: {
      Dashboard,
    },
    data() {
      return {
        text: '',
        textarea: ''
      }
    }
  }
</script>

<style lang="scss">
.notifications.el-card {
  border: none;
}

.notifications {
  .body {
    padding: 15px;
  }
  .el-card__body {
    padding: 0;
  }
  .header {
    background-color: #E87722;
    padding: 15px;
  }
  .header h5 {
    font-weight: bold;
    font-size: 1rem;
    color: white;
    margin: 0;
    }
  }
</style>